var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header_containers",class:{ header_containers_move: _vm.scrollTop > 50 && !_vm.notScroll },style:({ background: _vm.background })},[_c('div',{staticClass:"header_nav"},[_c('span',{staticClass:"nav_item hoverText",class:{
        nav_item_active: _vm.route.path == '/home' && _vm.nav_active == 'home',
      },on:{"click":function($event){return _vm.navTo('home')}}},[_vm._v("首页")]),_c('span',{staticClass:"nav_item hoverText",class:{
        nav_item_active:
          _vm.route.path == '/home' && _vm.nav_active == 'productServe',
      },on:{"click":function($event){return _vm.navTo('productServe')}}},[_vm._v("产品与服务")]),_c('span',{staticClass:"nav_item hoverText",class:{
        nav_item_active: _vm.route.path == '/home' && _vm.nav_active == 'platform',
      },on:{"click":function($event){return _vm.navTo('platform')}}},[_vm._v("平台介绍")]),_c('span',{staticClass:"nav_item hoverText",class:{
        nav_item_active: _vm.route.path == '/home' && _vm.nav_active == 'customer',
      },on:{"click":function($event){return _vm.navTo('customer')}}},[_vm._v("客户案例")]),_c('span',{staticClass:"nav_item hoverText",class:{
        nav_item_active: _vm.route.path == '/home' && _vm.nav_active == 'dynamic',
      },on:{"click":function($event){return _vm.navTo('dynamic')}}},[_vm._v("动态新闻")]),_c('span',{staticClass:"nav_item hoverText",class:{
        nav_item_active: _vm.route.path == '/aboutUs',
      },on:{"click":function($event){return _vm.navTo('aboutUs')}}},[_vm._v("关于我们")])]),_vm._m(0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header_login"},[_c('a',{staticClass:"login_item hoverText",attrs:{"href":"http://console.cn.hulu-cloud.com/","target":"_blank"}},[_vm._v("登陆")]),_c('a',{staticClass:"login_item hoverText",attrs:{"href":"http://help.cn.hulu-cloud.com/","target":"_blank"}},[_vm._v("帮助")])])}]

export { render, staticRenderFns }