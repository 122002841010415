<template>
  <div class="no_change_px">
    <div class="consult_wrap" :class="{ consult_wrap_show: scrollTop > 100 }">
      <div class="consult_phone_wrap">
        <div class="consult_item consult_phone">
          <div class="item_cont">
            <img src="@/assets/consult_phone.png" alt="" />
            <p class="name">电话咨询</p>
          </div>
        </div>
        <div class="phone_popup">
          <p class="text1">工作时间：9:00-18:00</p>
          <p class="text1">联系电话：021-39196758</p>
        </div>
      </div>
      <div class="consult_wechart">
        <div class="consult_item consult_wechart_cont">
          <div class="item_cont">
            <img src="@/assets/consult_wechart.png" alt="" />
            <p class="name">微信咨询</p>
          </div>
        </div>
        <div class="wechart_popup">
          <img
            src="@/assets/han-weixin.png"
            alt=""
          />
        </div>
      </div>
      <div class="consult_item" @click="$bus.$emit('showApply')">
        <div class="item_cont">
          <img src="@/assets/try_registered.png" alt="" />
          <p class="name">申请演示</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      scrollTop: 0,
    };
  },
  computed: {},
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      //或者使用document.querySelector('.class或者#id').scrollTop
      this.scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
    },
  },
};
</script>

<style lang="scss" scoped>
.no_change_px {
  .consult_wrap {
    width: 76px;
    background: rgba($color: #ffffff, $alpha: 1);
    box-shadow: 0px 2px 8px 2px #c2d7ed;
    border-radius: 38px;
    // overflow: hidden;
    position: fixed;
    right: 35px;
    bottom: 50px;
    z-index: 9999;
    transform: translateX(300px);
    transition: all 0.5s;

    .consult_item {
      width: 100%;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .item_cont {
        text-align: center;

        img {
          width: 28px;
          height: 23px;
        }

        .name {
          margin-top: 5px;
          text-align: center;
          @include semiboldFont(12px, #0f71ec);
        }
      }
    }

    .consult_phone_wrap {
      position: relative;

      .consult_phone {
        border-radius: 38px 38px 0 0;
        background: #0f71ec;

        .name {
          @include semiboldFont(12px, #ffffff !important);
        }
      }

      .consult_phone:hover ~ .phone_popup {
        display: block;
        opacity: 1;
      }

      .phone_popup{
        padding: 20px 30px;
        background: #ffffff;
        border-radius: 3px;
        position: absolute;
        top: 50%;
        right: 90px;
        transform: translateY(-50%);
        display: none;
        opacity: 0;

        &::before {
          content: "";
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: -20px;
          border-left: 10px solid #fff;
          border-right: 10px solid transparent;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
        }

        .text1{
          white-space: nowrap;
          @include regularFont(14px, #4A4A4A);
        }
      }
    }

    .consult_wechart {
      box-shadow: 0px 2px 4px 0px #edf0f5;
      position: relative;

      .consult_wechart_cont:hover ~ .wechart_popup {
        display: block;
        opacity: 1;
      }

      .wechart_popup {
        width: 150px;
        height: 150px;
        background: #ffffff;
        position: absolute;
        top: 50%;
        right: 90px;
        transform: translateY(-50%);
        transition: opacity 0.3s;
        display: none;
        opacity: 0;

        &::before {
          content: "";
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: -20px;
          border-left: 10px solid #fff;
          border-right: 10px solid transparent;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .consult_wrap_show {
    transform: translateX(0);
  }
}
</style>