<template>
  <div>
    <Header />
    <SuspensionConsult />
    <div class="recruitment_wrap">
      <div class="recruitment_text">
        <div>
          <p class="text1">人才招聘</p>
          <p class="text2">开启新的工作</p>
        </div>
      </div>
      <img
        class="recruitment_wrap_bg"
        src="@/assets/recruitment_bg.png"
        alt=""
      />
    </div>
    <div class="recruitment_cont">
      <div class="recruitment_item">
        <p class="item_title"><span>UI设计师</span></p>
        <p class="item_subtitle">招聘人数：1</p>
        <div class="item_cont">
          <p class="msg">职位描述：</p>
          <p class="msg">
            1、负责PC客户端，Web，App，小程序等项目的UI设计工作。 需要做原型图；
          </p>
          <p class="msg">
            2、关注设计产品功能点数据变化与用户体验的提升；
          </p>
          <p class="msg">
            3、参与完整设计流程，进行规范、模板的制定，并推进UI组件化建设；
          </p>
          <p class="msg">
            4、配合产品、前端团队输出标准设计文件并有效跟进开发效果，保证设计还原度，协助项目上线及产品的持续优化；
          </p>
          <p class="msg">职位要求：</p>
          <p class="msg">
            1、拥有1年及以上设计相关职位从业经验(能力优秀者的工作年限可适当放宽)；
          </p>
          <p class="msg">
            2、较强的创意、策划能力，良好的文字表达能力，思维敏捷；
          </p>
          <p class="msg">
            3、熟练使用 Photoshop、Sketch等常用设计制作软件；
          </p>
          <p class="msg">
            4、工作认真，有责任心，踏实肯干，富有团队精神；
          </p>
          <p class="msg">
            5、具备良好的美术基础，良好的创意构思能力；
          </p>
          <p class="msg">
            6、会使用Axure类软件者优先。
          </p>
        </div>
      </div>
      <div class="recruitment_item">
        <p class="item_title"><span>测试工程师</span></p>
        <p class="item_subtitle">招聘人数：1</p>
        <div class="item_cont">
          <p class="msg">职位描述：</p>
          <p class="msg">
            1、负责公司云智控产品的功能测试、接口测试工作；
          </p>
          <p class="msg">
            2、负责公司云智控产品的自动化测试开发及维护工作。
          </p>
          <p class="msg">职位要求：</p>
          <p class="msg">
            1、熟悉软件工程和方法理论，熟悉测试流程；
          </p>
          <p class="msg">
            2、熟练使用LoadRunner、Jmeter等性能测试工具；
          </p>
          <p class="msg">
            3、有较强的逻辑思维能力，能够从用户角度把控产品体验；
          </p>
          <p class="msg">
            4、有开发经验或自动化脚本编写能力者优先；
          </p>
          <p class="msg">
            5、热爱测试工作，具备较强的学习意愿和学习能力，具备良好沟通表达能力和团队合作精神。
          </p>
        </div>
      </div>
      <div class="recruitment_item">
        <p class="item_title"><span>高级GO开发工程师</span></p>
        <p class="item_subtitle">招聘人数：1</p>
        <div class="item_cont">
          <p class="msg">职位描述：</p>
          <p class="msg">
            1、理解和分析产品述求，在复杂业务逻辑背景下，高质高效交付后端价值。
          </p>
          <p class="msg">
            2、持续演进后台服务架构，推动业务复用能力的成长。
          </p>
          <p class="msg">
            3、持续的改进团队研发效能的提升工具和流程。
          </p>
          <p class="msg">职位要求：</p>
          <p class="msg">
            1、本科以上学历，精通C++，golang开发语言（可以C++/java转GO），良好的开发习惯，能够有意识的使用编码理论/开发范式提升代码的可维护性，熟悉微服务框架下的高性能服务器开发。
          </p>
          <p class="msg">
            2、精通mysql,mongodb,kafka,redis等常用的存储中间件系统的使用。
          </p>
          <p class="msg">
            3、对docker,container,k8s等常见的云原生服务治理能力有比较深入的理解和运用能力。
          </p>
          <p class="msg">
            4、良好的业务思维能力，熟悉和理解领域驱动设计，良好的沟通表达能力，良好的问题分析能力。
          </p>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
import SuspensionConsult from "@/components/suspensionConsult";
export default {
  components: {
    Header,
    Footer,
    SuspensionConsult,
  },
};
</script>

<style lang="scss" scoped>
.recruitment_wrap {
  width: 100%;
  height: 461px;
  background: #170c44;
  position: relative;

  .recruitment_text {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;

    .text1 {
      @include semiboldFont(60px, #ffffff);
    }

    .text2 {
      margin-left: 116px;
      @include regularFont(50px, #ffffff);
      font-weight: 200;
    }
  }

  .recruitment_wrap_bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.recruitment_cont {
  width: 100%;
  padding: 40px 208px 50px 208px;
  background: #f0f2f5;

  .recruitment_item {
    width: 100%;
    padding: 25px;
    border-radius: 8px;
    margin-bottom: 5px;

    .item_title {
      @include semiboldFont(24px, #4483f4);

      span {
        position: relative;

        &::after {
          content: "";
          width: 110px;
          height: 8px;
          background: #cee6ff;
          border-radius: 2px;
          opacity: 0.54;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 0px;
        }
      }
    }

    .item_subtitle {
      margin-top: 5px;
      @include regularFont(14px, #4a4a4a);
    }

    .item_cont {
      margin-top: 30px;

      .msg {
        margin-top: 10px;
        @include regularFont(14px, #8f959e);
      }
    }
  }

  .recruitment_item_active {
    background: #ffffff;
    box-shadow: 0px 2px 6px 0px #c2d6f8;
  }
}
</style>