<template>
  <div class="footer_containers">
    <div class="registered_wrap">
      <img
        class="registered_wrap_bg"
        src="@/assets/registered_wrap_bg.png"
        alt=""
      />
      <p class="registered_title">葫芦工业云，助力中国制造企业逐步升级</p>
      <button class="pointer registered_btn" @click="$bus.$emit('showApply')">
        申请演示
      </button>
    </div>
    <div class="record_message">
      <span
        >© Copyright 2020-2022 Boyuan Technology (Shanghai) Co., LTD
        上海博元信息科技有限公司</span
      >
      <a class="record_href" href="https://beian.miit.gov.cn/" target="_blank"
        >沪ICP备19027756号</a
      >
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.footer_containers {
  width: 100%;
  // height: 257px;
}

.registered_wrap {
  width: 100%;
  height: 257px;
  padding-top: 76px;
  background: #223f9c;
  border-radius: 1px;
  text-align: center;
  position: relative;
  bottom: 0;
  left: 0;
  overflow: hidden;

  .registered_wrap_bg {
    width: 395px;
    height: 276px;
    position: absolute;
    left: 200px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0.8;
  }

  .registered_title {
    text-align: center;
    @include semiboldFont(20px, #ffffff);
  }

  .registered_btn {
    width: 150px;
    height: 48px;
    background: #ffffff;
    border-radius: 24px;
    @include regularFont(20px, #4483f4);
    margin-top: 30px;
  }
}

.record_message {
  width: 100%;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0e1421;
  font-size: 10px;
  color: #bcbfc8;
  font-weight: 400;
  letter-spacing: 1px;

  .record_href {
    margin-left: 10px;
    color: #bcbfc8;
  }
}
</style>