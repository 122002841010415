<template>
  <div
    class="header_containers"
    :class="{ header_containers_move: scrollTop > 50 && !notScroll }"
    :style="{ background: background }"
  >
    <div class="header_nav">
      <span
        class="nav_item hoverText"
        :class="{
          nav_item_active: route.path == '/home' && nav_active == 'home',
        }"
        @click="navTo('home')"
        >首页</span
      >
      <span
        class="nav_item hoverText"
        :class="{
          nav_item_active:
            route.path == '/home' && nav_active == 'productServe',
        }"
        @click="navTo('productServe')"
        >产品与服务</span
      >
      <span
        class="nav_item hoverText"
        :class="{
          nav_item_active: route.path == '/home' && nav_active == 'platform',
        }"
        @click="navTo('platform')"
        >平台介绍</span
      >
      <span
        class="nav_item hoverText"
        :class="{
          nav_item_active: route.path == '/home' && nav_active == 'customer',
        }"
        @click="navTo('customer')"
        >客户案例</span
      >
      <span
        class="nav_item hoverText"
        :class="{
          nav_item_active: route.path == '/home' && nav_active == 'dynamic',
        }"
        @click="navTo('dynamic')"
        >动态新闻</span
      >
      <span
        class="nav_item hoverText"
        :class="{
          nav_item_active: route.path == '/aboutUs',
        }"
        @click="navTo('aboutUs')"
        >关于我们</span
      >
    </div>
    <div class="header_login">
      <a class="login_item hoverText" href="http://console.cn.hulu-cloud.com/" target="_blank">登陆</a>
      <a class="login_item hoverText" href="http://help.cn.hulu-cloud.com/" target="_blank">帮助</a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    background: {
      type: String,
    },
    notScroll: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      nav_active: "",
      scrollTop: 0,
    };
  },
  computed: {
    route() {
      return this.$route;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      //或者使用document.querySelector('.class或者#id').scrollTop
      this.scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
    },
    navTo(type) {
      console.log(this.route);
      if (type != "aboutUs") {
        if (this.route.path == "/home") {
          this.$emit("scrollIntoView", type);
        } else {
          this.$router.push({
            path: "/home",
            query: {
              pageViewType: type,
            },
          });
        }
      } else {
        if (this.route.path != "/aboutUs") {
          this.$router.push({
            path: "/aboutUs",
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header_containers {
  width: 100%;
  height: 56px;
  padding-left: 210px;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;

  .header_nav {
    .nav_item {
      margin-left: 95px;
      position: relative;
      cursor: pointer;
      @include regularFont(14px, #ffffff);

      &:first-child {
        margin-left: 0;
      }
    }

    .nav_item_active {
      &::after {
        content: "";
        width: 100%;
        height: 2px;
        background: #4483f4;
        border-radius: 1px;
        position: absolute;
        bottom: -4px;
        left: 0;
      }
    }
  }

  .header_login {
    margin-left: 140px;

    .login_item {
      margin-left: 32px;
      cursor: pointer;
      @include regularFont(14px, #ffffff);

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .hoverText {
    &:hover {
      color: #4483f4;
    }
  }
}

.header_containers_move {
  background: rgba($color: #000000, $alpha: 0.2);
}
</style>
